import AdPreviewComposition from './AdPreviewComposition/AdPreviewComposition';
import AdPreview from './AdPreview/AdPreview';
import Section from '../Section/Section';

const AdCard = ({ buttonGroup, adPreview }) => {
  return (
    <Section>
      <AdPreviewComposition
        adPreview={<AdPreview adPreview={adPreview} />}
        buttonGroup={buttonGroup}
      />
    </Section>
  );
};

export default AdCard;
