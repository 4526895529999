import { FormattedMessage, FormattedNumber } from 'react-intl';

import FormattedDateTime from '../../FormattedDateTime/FormattedDateTime';
import IconContainer from '../../IconContainer/IconContainer';
import { IconImage } from '../../icons';
import Price from '../../Price/Price';
import Media from '../../Media/Media';
import cn from 'classnames';

import styles from './AdPreview.module.scss';
import IconSofa from '../../icons/Sofa/Sofa';
import IconSnapshot from '../../icons/Snapshot/Snapshot';
import IconStairs from '../../icons/Stairs/Stairs';
import IconYear from '../../icons/Year/Year';
import IconBuildingType from '../../icons/BuildingType/BuildingType';
import IconRenew from '../../icons/Renew/Renew';
import IconAddCircle from '../../icons/AddCircle/AddCircle';

const AdPreviewParams = ({ adPreview }) => {
  const floor = `${adPreview.floor} ${
    adPreview.floorCount ? `з ${adPreview.floorCount}` : ''
  }`;
  const area = `${adPreview.totalArea || '-'} / ${
    adPreview.livingArea || '-'
  } / ${adPreview.kitchenArea || '-'}`;
  const isAreaShown =
    adPreview.totalArea || adPreview.livingArea || adPreview.kitchenArea;

  return (
    <div className={styles.params}>
      <div className={styles.paramsGrid}>
        <div className={styles.paramsColumn}>
          {adPreview.roomCount && (
            <div className={styles.paramsItem}>
              <IconContainer icon={<IconSofa />} />
              {adPreview.roomCount}{' '}
              <FormattedMessage
                defaultMessage="{num, plural, one {кімната} few {кімнати} many {кімнат} other {кімнати}}"
                values={{ num: adPreview.roomCount }}
              />
            </div>
          )}

          {isAreaShown && (
            <div className={styles.paramsItem}>
              <IconContainer icon={<IconSnapshot />} />
              <FormattedMessage
                defaultMessage="{area} м²"
                values={{ area: area }}
              />
            </div>
          )}

          {adPreview.floor && (
            <div className={styles.paramsItem}>
              <IconContainer icon={<IconStairs />} />
              <FormattedMessage defaultMessage="поверх " /> {floor}
            </div>
          )}
        </div>

        <div className={styles.paramsColumn}>
          {adPreview.wallType && (
            <div className={styles.paramsItem}>
              <IconContainer icon={<IconBuildingType />} />
              <div className={styles.paramsItem}>{adPreview.wallType}</div>
            </div>
          )}

          {adPreview.yearOfBuilding > 0 && (
            <div className={styles.paramsItem}>
              <IconContainer icon={<IconYear />} />
              <div className={styles.paramsItem}>
                {adPreview.yearOfBuilding}
              </div>
            </div>
          )}
        </div>
      </div>
      <div className={styles.timeRow}>
        {adPreview.updateTime && (
          <div className={cn(styles.updateTime, styles.paramsColumn)}>
            <IconContainer icon={<IconRenew />} />
            <FormattedDateTime dateISO={adPreview.updateTime} />
          </div>
        )}
        {adPreview.insertTime && (
          <div className={cn(styles.updateTime, styles.paramsColumn)}>
            <IconContainer icon={<IconAddCircle />} />
            <FormattedMessage defaultMessage="створено " />{' '}
            <FormattedDateTime
              dateISO={adPreview.insertTime}
              showTime={false}
            />
          </div>
        )}
      </div>
    </div>
  );
};

const AdPreview = ({ adPreview }) => {
  const isPriceShown = adPreview.price.number && adPreview.price.currency;
  const isPricePerMeterShown = isPriceShown && adPreview.totalArea;
  const isParamsGridShown =
    adPreview.roomCount ||
    adPreview.floor ||
    adPreview.totalArea ||
    adPreview.livingArea ||
    adPreview.kitchenArea ||
    adPreview.houseType ||
    adPreview.yearOfBuilding ||
    adPreview.wallType;

  return (
    <div className={styles.root}>
      <div className={styles.grid}>
        <div className={styles.imageColumn}>
          <div className={styles.image}>
            {adPreview.media ? (
              <Media media={adPreview.media} isAdjustable />
            ) : (
              <IconContainer size="inline">
                <IconImage />
              </IconContainer>
            )}
          </div>
        </div>

        <div className={styles.contentColumn}>
          <div className={styles.content}>
            <div className={styles.priceRow}>
              {isPriceShown && (
                <div className={styles.price}>
                  <Price
                    number={adPreview.price.number}
                    currency={adPreview.price.currency}
                  />
                </div>
              )}

              {isPricePerMeterShown && (
                <div className={styles.pricePerMeter}>
                  <FormattedNumber
                    value={Math.ceil(
                      adPreview.price.number / adPreview.totalArea,
                    )}
                  />{' '}
                  {adPreview.price.currency}{' '}
                  <FormattedMessage defaultMessage="for m²" />
                </div>
              )}
            </div>

            {adPreview.title && (
              <div className={styles.title}>{adPreview.title}</div>
            )}

            {adPreview.apartmentComplex && (
              <div className={styles.apartmentComplex}>
                {adPreview.apartmentComplex}
              </div>
            )}

            {adPreview.description && (
              <div className={styles.description}>{adPreview.description}</div>
            )}

            {isParamsGridShown && <AdPreviewParams adPreview={adPreview} />}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdPreview;
