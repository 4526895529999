@import '../../../styles/utils';

.root {
  overflow: hidden;
}

.grid {
  display: flex;
  margin: 0 -12px;
}

.imageColumn {
  flex: 0 0 360px;
  padding: 0 12px;
}

.image {
  aspect-ratio: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: $secondaryBorderRadius;
  background-color: $grey_1;
  font-size: 80px;
  color: $grey_2;
  overflow: hidden;
}

.contentColumn {
  flex: 1 1 auto;
  padding: 0 12px;
  padding-top: 16px;
}

.content {
  padding-right: 8px;
}

.price {
  margin-bottom: 4px;
  font-size: 22px;
}

.pricePerMeter {
  margin-bottom: 12px;
  color: $grey_3;
}

.title {
  margin-bottom: 4px;
  font-size: 18px;
  font-weight: 500;
}

.apartmentComplex {
  margin-bottom: 12px;
  color: $grey_3;
}

.description {
  margin-bottom: 12px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.params {
  display: flex;
  flex-direction: column;
}

.paramsGrid {
  display: flex;
  margin-bottom: 16px;
}

.paramsColumn {
  margin-right: 32px;
  width: 50%;

  &:last-child {
    margin-right: 0;
  }
}

.paramsItem {
  margin-bottom: 8px;
  display: flex;
  align-items: center;
  gap: 10px;

  &:last-child {
    margin-bottom: 0;
  }
}

.updateTime {
  color: $grey_3;
  font-size: 13px;
  display: flex;
  align-items: center;
  gap: 10px;
}

.timeRow {
  display: flex;
}

.priceRow {
  display: flex;
  justify-content: space-between;
}
