import { FormattedNumber } from 'react-intl';
import styles from './Price.module.scss';

const Price = ({ number, currency }) => (
  <span className={styles.root}>
    {String(number) !== '0' && currency !== 'грн' && (
      <span className={styles.currency}>
        {currency}
        {'  '}
      </span>
    )}
    <span>
      <FormattedNumber value={number} />
    </span>

    {String(number) !== '0' && currency === 'грн' && (
      <span className={styles.currency}>
        {'  '}
        {'грн'}
      </span>
    )}
  </span>
);

export default Price;
