import { FormattedMessage } from 'react-intl';
import cx from 'classnames';

import Media from '../../../components/Media/Media';
import Link from '../../../components/Link/Link';
import IconContainer from '../../../components/IconContainer/IconContainer';
import { IconRoundedCheck, IconRoundedCross } from '../../../components/icons';

import { checkIsAdPhotoBroken } from '../../../utils';
import {
  AD_PHOTO_REPAIR_QUALITY_INFO,
  AD_PHOTO_CATEGORY_INFO,
} from '../../../constants';
import styles from './PhotoPreviewCard.module.scss';

const createSize = (width, height) => `${width}×${height}`;

const PhotoPreviewCardInfoRow = ({ description, value }) => (
  <div className={styles.infoRow}>
    <div className={styles.infoItem}>{description}</div>

    <div className={styles.infoItem}>{value}</div>
  </div>
);

const PhotoPreviewCard = ({ photo }) => {
  const { id, url, repairQuality, category, size } = photo;
  const isBroken = checkIsAdPhotoBroken(photo);

  return (
    <div className={cx(styles.root, { [styles.root_broken]: isBroken })}>
      <div className={styles.idRow}>
        <div>{id}</div>

        <div className={styles.isBrokenIcon}>
          <IconContainer
            icon={isBroken ? <IconRoundedCross /> : <IconRoundedCheck />}
          />
        </div>
      </div>

      <div className={styles.grid}>
        <div className={styles.imageColumn}>
          <Media
            media={{ title: `Photo #${id}`, source: photo.url.lun }}
            isAdjustable
          />
        </div>

        <div className={styles.contentColumn}>
          <div className={styles.linksRow}>
            <div className={styles.link}>
              <Link
                component="a"
                title={<FormattedMessage defaultMessage="Partner's photo" />}
                href={url.partner}
                target="_blank"
                rel="noopener noreferrer"
              />
            </div>

            <div className={styles.link}>
              <Link
                component="a"
                title={<FormattedMessage defaultMessage="Our photo" />}
                href={url.lun}
                target="_blank"
                rel="noopener noreferrer"
              />
            </div>
          </div>
          <div>
            <PhotoPreviewCardInfoRow
              description={
                <FormattedMessage defaultMessage="Partner's photo size" />
              }
              value={createSize(size.partner.width, size.partner.height)}
            />

            <PhotoPreviewCardInfoRow
              description={<FormattedMessage defaultMessage="Actual size" />}
              value={createSize(size.lun.width, size.lun.height)}
            />

            <PhotoPreviewCardInfoRow
              description={<FormattedMessage defaultMessage="Category" />}
              value={
                AD_PHOTO_CATEGORY_INFO[category] ? (
                  <FormattedMessage
                    {...AD_PHOTO_CATEGORY_INFO[category].title}
                  />
                ) : (
                  category
                )
              }
            />

            <PhotoPreviewCardInfoRow
              description={<FormattedMessage defaultMessage="Repair type" />}
              value={
                repairQuality && (
                  <FormattedMessage
                    {...AD_PHOTO_REPAIR_QUALITY_INFO[repairQuality].title}
                  />
                )
              }
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default PhotoPreviewCard;
