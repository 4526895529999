import { useCallback, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { AD_PAGE_SECTION } from '../../constants';
import { getAdPageLink } from '../../routes';

const useRoutingViaTabs = (isStandalone = true) => {
  const { adId, section } = useParams();
  const navigate = useNavigate();

  const handleSectionChange = useCallback(
    (section) => {
      if (isStandalone) {
        navigate(getAdPageLink(adId, section));
      }
    },
    [adId, navigate, isStandalone],
  );

  useEffect(() => {
    if (isStandalone && !Object.values(AD_PAGE_SECTION).includes(section)) {
      navigate(getAdPageLink(adId), { replace: true });
    }
  }, [section, adId, navigate, isStandalone]);

  return [section, handleSectionChange];
};

export default useRoutingViaTabs;
