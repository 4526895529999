import { useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { useAction, useAtom } from '@reatom/react';
import { FormattedMessage, useIntl } from 'react-intl';

import Page from '../../components/Page/Page';
import PageMainContentTile from '../../components/PageMainContentTile/PageMainContentTile';
import Loader from '../../components/Loader/Loader';
import Section from '../../components/Section/Section';
import NoResultsPlaceholder from '../../components/NoResultsPlaceholder/NoResultsPlaceholder';
import Tabs from '../../components/Tabs/Tabs';
import CallsTable from '../../components/CallsTable/CallsTable';
import TasksTable from '../../components/TasksTable/TasksTable';
import TileSection from '../../components/TileSection/TileSection';
import AdStatusMessage from './AdStatusMessage/AdStatusMessage';
import AdInteractionButtonGroup from './AdInteractionButtonGroup/AdInteractionButtonGroup';
import AdPageTabsControlItem from './TabsControlItem/TabsControlItem';
import AdPhotosTable from './AdPhotosTable/AdPhotosTable';
import PhotoPreviewCard from './PhotoPreviewCard/PhotoPreviewCard';
import PhotoPreviewCardGrid from './PhotoPreviewCardGrid/PhotoPreviewCardGrid';
import RelatedResourcesLinks from './RelatedResourcesLinks/RelatedResourcesLinks';
import AdPageTitleAppended from './TitleAppended/TitleAppended';
import AdDetails from './AdDetails/AdDetails';

import * as Ads from '../../models/ads';
import * as Tasks from '../../models/tasks';
import { AD_STATUS, AD_PAGE_SECTION } from '../../constants';
import { useNotifications, useTaskOverviewDialog } from '../../hooks';
import { checkIsAdPhotoBroken } from '../../utils';
import withRoles from '../../components/PagesScene/withRoles';
import useRoutingViaTabs from './useRoutingViaTabs';
import useAdStatus from './useAdStatus';
import styles from './AdPage.module.scss';
import AdCard from '../../components/AdCard/AdCard';

const formAdInInterfaceLink = (adId) => `https://flatfy.ua/realty/${adId}`;

const AdPageLayout = ({ children, relatedResourcesLinks, adId }) => (
  <FormattedMessage defaultMessage="Ad #{ad_id}" values={{ ad_id: adId }}>
    {([helmetTitle]) => (
      <Page helmetTitle={helmetTitle}>
        <PageMainContentTile
          title={<FormattedMessage defaultMessage="Advertisement" />}
          titleAppended={
            <AdPageTitleAppended
              adId={adId}
              relatedResourcesLinks={relatedResourcesLinks}
            />
          }
        >
          {children}
        </PageMainContentTile>
      </Page>
    )}
  </FormattedMessage>
);

const AdPage = ({ adId: propsAdId, isStandalone = true }) => {
  const intl = useIntl();
  const { showErrorNotification } = useNotifications();

  const { adId: paramsAdId } = useParams();
  const adId = propsAdId ?? paramsAdId;
  const [section, onSectionChange] = useRoutingViaTabs(isStandalone);
  const [
    { adStatus, isAdStatusLoading },
    { onAdRemove, onAdPublish, onAdReprocess },
  ] = useAdStatus(adId);

  const adPreview = useAtom(Ads.Overview.makeAdPreviewAtom(adId));
  const adDetails = useAtom(Ads.Overview.makeAdDetailsAtom(adId));
  const adCalls = useAtom(Ads.Overview.makeAdCallsAtom(adId));
  const adTasksWithoutComplaints = useAtom(
    Ads.Overview.makeAdTasksWithoutComplaintsAtom(adId),
  );
  const adComplaintTasks = useAtom(Ads.Overview.makeAdComplaintTasksAtom(adId));
  const adPhotos = useAtom(Ads.Overview.makeAdPhotosAtom(adId));
  const isAdLoading = useAtom(Ads.Overview.makeIsAdLoadingAtom(adId));
  const isAdIdle = useAtom(Ads.Overview.makeIsAdIdleAtom(adId));
  const isAdSucceeded = useAtom(Ads.Overview.makeIsAdSucceededAtom(adId));
  const isAdFailed = useAtom(Ads.Overview.makeIsAdFailedAtom(adId));

  const shownAd = useAction(Ads.Overview.shown);

  useEffect(() => {
    if (!isAdFailed) {
      return;
    }

    showErrorNotification(
      intl.formatMessage({
        defaultMessage: 'Failed to load ad. Please try again',
      }),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAdFailed, intl.formatMessage, showErrorNotification]);

  const loadingOverviewTaskIdsAtom = useAtom(Tasks.Overview.loadingTaskIdsAtom);
  const { open: onTaskEditingDialogOpen } = useTaskOverviewDialog();

  const handleTaskEditClick = (taskId) => onTaskEditingDialogOpen(taskId);
  const handleAdRemove = () => onAdRemove({ adId, reason: '' });
  const handleAdPublish = () => onAdPublish({ adId, reason: '' });
  const handleAdReprocess = () => onAdReprocess({ adId });

  const hasBrokenPhotos = useMemo(
    () =>
      adPhotos.map((adPhoto) => checkIsAdPhotoBroken(adPhoto)).some(Boolean),
    [adPhotos],
  );

  useEffect(() => {
    if (isAdSucceeded || isAdLoading) {
      return;
    }

    shownAd({ adId });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [adId, shownAd]);

  if (isAdFailed) {
    return (
      <AdPageLayout adId={adId}>
        <NoResultsPlaceholder
          title={
            <FormattedMessage defaultMessage="Oops! Couldn't find an advertisement :(" />
          }
        />
      </AdPageLayout>
    );
  }

  if (isAdLoading || isAdIdle) {
    return (
      <AdPageLayout adId={adId}>
        <div className={styles.loader}>
          <Loader />
        </div>
      </AdPageLayout>
    );
  }

  const adPrimarySourceLink = '';
  const adGroupLink = '';
  const adInCatalogLink = '';

  return (
    <AdPageLayout
      adId={adId}
      relatedResourcesLinks={
        <RelatedResourcesLinks
          adPrimarySourceLink={adPrimarySourceLink}
          adGroupLink={adGroupLink}
          adIdInterfaceLink={
            adStatus.status !== AD_STATUS.NOT_IN_INTERFACE &&
            formAdInInterfaceLink(adId)
          }
          adInCatalogLink={adInCatalogLink}
        />
      }
    >
      <Section>
        <AdStatusMessage adStatus={adStatus} />
      </Section>

      {adStatus.status !== AD_STATUS.FIRST_IN_INTERFACE && (
        <AdCard
          adPreview={adPreview}
          buttonGroup={
            <AdInteractionButtonGroup
              adStatus={adStatus.status}
              isLoading={isAdStatusLoading}
              onRemoveClick={handleAdRemove}
              onPublishClick={handleAdPublish}
              onReprocessClick={handleAdReprocess}
            />
          }
        />
      )}

      <Tabs defaultValue={section} onChange={onSectionChange}>
        <Tabs.ControlGroup>
          <AdPageTabsControlItem
            title={<FormattedMessage defaultMessage="Details" />}
            value={AD_PAGE_SECTION.DETAILS}
            hasWarning={
              !adDetails.geo.apartmentComplex.recognized &&
              !adDetails.geo.house.recognized
            }
          />
          <AdPageTabsControlItem
            title={<FormattedMessage defaultMessage="Photos" />}
            value={AD_PAGE_SECTION.PHOTOS}
            hasWarning={hasBrokenPhotos}
          />
          <AdPageTabsControlItem
            title={<FormattedMessage defaultMessage="Calls" />}
            value={AD_PAGE_SECTION.CALLS}
            count={adCalls.length}
          />
          <AdPageTabsControlItem
            title={<FormattedMessage defaultMessage="Tasks" />}
            count={adTasksWithoutComplaints.length}
            value={AD_PAGE_SECTION.TASKS}
          />
          <AdPageTabsControlItem
            title={<FormattedMessage defaultMessage="Complaints" />}
            count={adComplaintTasks.length}
            value={AD_PAGE_SECTION.COMPLAINTS}
          />
        </Tabs.ControlGroup>

        <Tabs.PanelGroup>
          <Tabs.PanelItem value={AD_PAGE_SECTION.DETAILS}>
            <AdDetails adId={adId} />
          </Tabs.PanelItem>

          <Tabs.PanelItem value={AD_PAGE_SECTION.PHOTOS}>
            <TileSection
              title={<FormattedMessage defaultMessage="Raw data" />}
              isGrey
            >
              <AdPhotosTable photos={adPhotos} />
            </TileSection>

            {adPhotos.length > 0 && (
              <TileSection
                title={<FormattedMessage defaultMessage="Recognized data" />}
                isGrey
              >
                <PhotoPreviewCardGrid>
                  {adPhotos.map((photo) => (
                    <PhotoPreviewCardGrid.Item key={photo.id}>
                      <PhotoPreviewCard photo={photo} />
                    </PhotoPreviewCardGrid.Item>
                  ))}
                </PhotoPreviewCardGrid>
              </TileSection>
            )}
          </Tabs.PanelItem>

          <Tabs.PanelItem value={AD_PAGE_SECTION.CALLS}>
            <CallsTable calls={adCalls} />
          </Tabs.PanelItem>

          <Tabs.PanelItem value={AD_PAGE_SECTION.TASKS}>
            <TasksTable
              tasks={adTasksWithoutComplaints}
              loadingOverviewTaskIdsAtom={loadingOverviewTaskIdsAtom}
              onTaskEditClick={handleTaskEditClick}
            />
          </Tabs.PanelItem>

          <Tabs.PanelItem value={AD_PAGE_SECTION.COMPLAINTS}>
            <TasksTable
              tasks={adComplaintTasks}
              loadingOverviewTaskIdsAtom={loadingOverviewTaskIdsAtom}
              onTaskEditClick={handleTaskEditClick}
            />
          </Tabs.PanelItem>
        </Tabs.PanelGroup>
      </Tabs>
    </AdPageLayout>
  );
};

AdPage.RouteParams = withRoles()({
  element: <AdPage />,
  path: '/ad/:adId',
  children: [
    { index: true, element: <AdPage /> },
    { path: ':section', element: <AdPage /> },
  ],
});

export default AdPage;
