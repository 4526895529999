import { LANGUAGE } from './constants';

const config = {
  sentryClientDns:
    process.env.WEB_APP_SENTRY_CLIENT_DSN ||
    'https://e0c97e57acc415763f2f1a8fef441854@sentry.bird.rent/10',

  defaultLanguage: process.env.WEB_APP_DEFAULT_LANGUAGE || LANGUAGE.EN,
};

export default config;
