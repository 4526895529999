import { FormattedMessage } from 'react-intl';

import Link from '../../../components/Link/Link';
import AdPageTable from './AdPageTable/AdPageTable';

import {
  AD_PHOTO_DOWNLOAD_STATUS_INFO,
  AD_PHOTO_PROCESSING_STAGE_INFO,
} from '../../../constants';

const COLUMNS = [
  { name: 'id', width: '112px', isFlexible: false },
  { name: 'partnerUrl', width: '250px', isFlexible: true },
  { name: 'processingStage', width: '160px', isFlexible: true },
  { name: 'downloadStatus', width: '160px', isFlexible: false },
];

const AdPhotosTable = ({ photos }) => (
  <AdPageTable
    headerRow={
      <AdPageTable.HeaderRow>
        <AdPageTable.HeaderCell
          name="id"
          title={<FormattedMessage defaultMessage="Photo Id" />}
        />
        <AdPageTable.HeaderCell
          name="partnerUrl"
          title={<FormattedMessage defaultMessage="Partner link" />}
        />
        <AdPageTable.HeaderCell
          name="processingStage"
          title={<FormattedMessage defaultMessage="Processing stage" />}
        />
        <AdPageTable.HeaderCell
          name="downloadStatus"
          title={<FormattedMessage defaultMessage="Downloading status" />}
        />
      </AdPageTable.HeaderRow>
    }
    emptyText={<FormattedMessage defaultMessage="No photos" />}
    columns={COLUMNS}
    isEmpty={photos.length < 1}
  >
    {photos.map(({ id, url, processingStage, downloadStatus }) => (
      <AdPageTable.Row key={id}>
        <AdPageTable.Cell name="id">{id}</AdPageTable.Cell>

        <AdPageTable.Cell name="partnerUrl">
          <Link
            component="a"
            href={url.partner}
            title={url.partner}
            target="_blank"
            rel="noopener noreferrer"
          />
        </AdPageTable.Cell>

        <AdPageTable.Cell name="processingStage">
          {AD_PHOTO_PROCESSING_STAGE_INFO[processingStage] ? (
            <FormattedMessage
              {...AD_PHOTO_PROCESSING_STAGE_INFO[processingStage].title}
            />
          ) : (
            processingStage
          )}
        </AdPageTable.Cell>

        <AdPageTable.Cell name="downloadStatus">
          <FormattedMessage
            {...AD_PHOTO_DOWNLOAD_STATUS_INFO[downloadStatus]?.title}
          />
        </AdPageTable.Cell>
      </AdPageTable.Row>
    ))}
  </AdPageTable>
);

export default AdPhotosTable;
