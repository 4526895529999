const PRIORITY = {
  LOW: 'low',
  MEDIUM: 'medium',
  HIGH: 'high',
};

const PRIORITY_INFO = {
  [PRIORITY.LOW]: {
    slug: PRIORITY.LOW,
    title: 'низький',
  },

  [PRIORITY.MEDIUM]: {
    slug: PRIORITY.MEDIUM,
    title: 'середній',
  },

  [PRIORITY.HIGH]: {
    slug: PRIORITY.HIGH,
    title: 'високий',
  },
};

const SORTED_PRIORITIES = [PRIORITY.LOW, PRIORITY.MEDIUM, PRIORITY.HIGH];

export { PRIORITY, PRIORITY_INFO, SORTED_PRIORITIES };
