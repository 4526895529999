import Svg from '../Svg/Svg';

const IconBuildingType = () => (
  <Svg xmlns="http://www.w3.org/2000/svg" fill="#000" viewBox="0 0 20 20">
    <path
      fill-rule="evenodd"
      d="M2.20012 7.6V5.2h4.2v2.4h-4.2Zm7.2 0h-1.8V5.2h4.19998v2.4H9.40012Zm-.6 1.2h-4.2v2.4h4.2V8.8Zm.6 3.6h-1.8v2.4h4.19998v-2.4H9.40012Zm3.59998 0v2.4h4.2v-2.4h-4.2Zm2.4-1.2h2.4c.3314 0 .6.2686.6.6v3.6c0 .3314-.2686.6-.6.6H1.60012c-.33137 0-.6-.2686-.6-.6v-3.6c0-.3314.26863-.6.6-.6h1.8V8.8h-1.8c-.33137 0-.6-.26863-.6-.6V4.6c0-.33137.26863-.6.6-.6H17.8001c.3314 0 .6.26863.6.6v3.6c0 .33137-.2686.6-.6.6h-2.4v2.4Zm-1.2 0V8.8h-4.2v2.4h4.2Zm.6006-3.6h2.3994V5.2h-4.2v2.4h1.8006ZM4.00012 12.4h2.4v2.4h-4.2v-2.4h1.8Z"
      clip-rule="evenodd"
    />
  </Svg>
);

export default IconBuildingType;
